










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarsSharedService, { CarsSharedServiceS } from '../../cars-shared.service';

@Component
export default class CalculatedPricesTooltip extends Vue {
    @Inject(CarsSharedServiceS) protected carsSharedService!: CarsSharedService;

    @Prop({
        type: String,
    })
    private provider!: string;

    get calculations() {
        return this.carsSharedService.getExtraExpensesByProvider(this.provider).map(item => item.description);
    }
}
